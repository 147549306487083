<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 365px;">
        <img
          :src="formData.imagen && typeof formData.imagen == 'object'? formData.imagen.imagen : require('./../assets/noimage.png')"
          style="position:absolute;width:52px; height:52px; top: 4px; left:6px;"
        />
        <field
          name="codigo"
          widget="char"
          searchable
          placeholder="Cód."
          width="67px"
          style="top:10px; left:68px;"
          readonly
          inputalign="center"
          fontweight="bold"
        />
        <field
          name="nombre_fiscal"
          searchable
          widget="char"
          help="Razon Social"
          inline-label
          placeholder="Nombre fiscal"
          readonly
          width="220px"
          style="top:10px; left:145px;"
        />
        <field
          name="nombre_comercial"
          searchable
          widget="char"
          help="Nombre Comercial"
          required
          placeholder="Nombre comercial"
          width="302px"
          style="top:35px; left:68px;"
          fontweight="bold"
          readonly
        />

        <field
          name="direccion_fiscal"
          searchable
          widget="char"
          help="Direccion Fiscal"
          inline-label
          placeholder="Direccion fiscal"
          width="175px"
          style="top:60px; left:10px;"
          readonly
        />
        <field
          name="direccion_fiscal1"
          searchable
          widget="char"
          help="Direccion Fiscal"
          inline-label
          placeholder="Direccion fiscal"
          width="175px"
          style="top:60px; left:190px;"
          readonly
        />
        <field
          name="cp_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="CP"
          width="60px"
          style="top:85px; left:10px;"
          readonly
        />
        <field
          name="poblacion_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="Poblacion"
          width="150px"
          style="top:85px; left:75px;"
          readonly
        />
        <field
          name="provincia_fiscal_id"
          searchable
          widget="m2o"
          inline-label
          placeholder="Provincia"
          width="145px"
          style="top:85px; left:230px;"
          readonly
        />
        <field
          name="telefono_fiscal"
          searchable
          widget="char"
          inline-label
          placeholder="Telefono"
          width="100px"
          style="top:60px; left:250px;"
          readonly
        />

        <span class="caja_saldos" style="left:388px">
          <div>Saldo Virtual</div>
          <div class="saldo_virtual">{{saldo_virtual}}€</div>
        </span>
        <span class="caja_saldos" style="left:480px">
          <div>Metal</div>
          <div class="metal">{{metal}}gr.</div>
        </span>
        <span class="caja_saldos" style="left:572px">
          <div>Saldo Real</div>
          <div class="saldo_real">{{saldo_real}}€</div>
        </span>

        <select
          v-if="mode=='view'"
          ref="filtro"
          name="filtro"
          style="position:absolute;width:200px;left:50%;top:120px;margin-left:-100px"
          :disabled="!itemId"
          @change="filtrar"
        >
          <option value="activos">Activos ({{n_activos}})</option>
          <option value="ocultos">Ocultos ({{n_ocultos}})</option>
          <option value="inactivos">Inactivos ({{n_inactivos}})</option>
          <option value="todos">Todos ({{n_total}})</option>
        </select>

        <field
          ref="movimientos"
          name="movimientos"
          widget="handsontable"
          :height="209"
          :minRows="1"
          style="top:150px; left:4px; width: 648px;"
          customprop="movimientos_customprop"
          :htSettings="movimientos_customprop"
          :fields="['factura_id','wt']"
          :columns="[
                {name:'movimiento',header:'Movimiento',type:'select',options:{'venta':'Venta','cobro':'Cobro','impago':'Impago','abono':'Abono'}},
                {name:'tipo',header:'Tipo',type:'select'},
                {name:'estado',header:'Estado'},
                {name:'fecha',header:'Fecha',type:'date2'},
                {name:'concepto',header:'Concepto'},
                {name:'numero',header:'Nº'},
                {name:'compra_eur',header:'Compra(€)',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'pago_eur',header:'Pago(€)',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'compra_gr',header:'Compra(gr.)',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'pago_gr',header:'Pago(gr.)',type:'numeric',numericFormat: { pattern: '0,0.00'}},
                {name:'ocultar',header:'O',type:'checkbox'},
                {name:'inactivo',header:'I',type:'checkbox'},
              ]"
        ></field>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<style>
.caja_saldos {
  position: absolute;
  top: 10px;
}
.caja_saldos > div:last-child {
  border: 1px #aaa solid;
  font-size: 1.1em;
  width: 5.5em;
  text-align: center;
  height: 2em;
  line-height: 2em;
}
</style>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";

function floatOrCero(v) {
  v = parseFloat(v || 0);
  if (!isFinite(v)) return 0;
  return v;
}
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Saldos de representantes",
      dbAdapter: "representante",
      primary: "codigo",
      variablesMovimientos: {
        estados: {
          pendiente_remesar: {
            label: "Pte. Remesar",
            background: "#FFFFBB"
          },
          remesado: {
            label: "Remesado",
            background: "#CEC"
          },
          devuelto: {
            label: "Devuelto",
            background: "red"
          },
          pendiente_cobro: {
            label: "Pte. Cobro",
            background: "#FFFFBB"
          },
          cobrado: {
            label: "Cobrado",
            background: "#CEC"
          },
          entregado_representante: {
            label: "Entregado Rep.",
            background: "#FFDDAA"
          },
          pendiente_solucionar: {
            label: "Pte. Solucionar",
            background: "#FFFFBB"
          },
          solucionado: {
            label: "Solucionado",
            background: "#CEC"
          }
        },
        tipos: {
          factura: {
            label: "Factura",
            selectOptions: {}
          },
          propuesta_pedido: {
            label: "Prop. Pedido",
            selectOptions: {}
          },
          letra: {
            label: "Letra",
            selectOptions: {
              pendiente_remesar: "Pte. Remesar",
              remesado: "Remesado",
              devuelto: "Devuelto"
            }
          },
          cheque: {
            label: "Cheque/Pagaré",
            selectOptions: {
              pendiente_cobro: "Pte. Cobro",
              cobrado: "Cobrado",
              devuelto: "Devuelto"
            }
          },
          transferencia: {
            label: "Trans. Bancaria",
            selectOptions: { cobrado: "Cobrado", devuelto: "Devuelto" }
          },
          efectivo: {
            label: "Efectivo",
            selectOptions: {
              cobrado: "Cobrado",
              entregado_representante: "Entregado Rep."
            }
          }
        },
        movimientos: {
          venta: {
            label: "Venta",
            selectOptions: {
              propuesta_pedido: "Prop. Pedido",
              factura: "Factura"
            }
          },
          cobro: {
            label: "Cobro",
            selectOptions: {
              letra: "Letra",
              cheque: "Cheque/Pagaré",
              transferencia: "Trans. Bancaria",
              efectivo: "Efectivo"
            }
          },
          impago: {
            label: "Impago",
            selectOptions: {}
          }
        }
      },
      movimientos_customprop: {
        cells: function(row, col, prop) {
          var cellProperties = {};
          if (!self.$refs.movimientos.field) return;
          let h = self.$refs.movimientos.field.hotInstance;
          if (typeof h.getSourceData()[row] == "undefined") return;
          var movimiento = h.getSourceData()[row]["movimiento"];
          var tipo = h.getSourceData()[row]["tipo"];
          var wt = h.getSourceData()[row]["wt"];
          var factura_id = h.getSourceData()[row]["factura_id"];
          cellProperties.readOnly =
            self.mode == "empty" ||
            self.mode == "view" ||
            (factura_id && !(prop == "ocultar" || prop == "inactivo"));
          if (prop == "numero") {
            cellProperties.renderer = function(
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              if (parseInt(wt)) {
                td.style.color = "black!important";
                window.$(td).addClass("plum");
              }
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            };
          }
          // ESTADO
          if (prop == "estado") {
            cellProperties.editor = "select";
            cellProperties.renderer = function(
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              td.style.background = (
                self.variablesMovimientos.estados[value] || {
                  background: ""
                }
              ).background;
              arguments[5] = (
                self.variablesMovimientos.estados[value] || {
                  label: ""
                }
              ).label;
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            };

            if (movimiento == "impago") {
              cellProperties.selectOptions = {
                pendiente_solucionar: "Pte. Solucionar",
                solucionado: "Solucionado"
              };
            } else {
              cellProperties.selectOptions = (
                self.variablesMovimientos.tipos[tipo] || {
                  selectOptions: {}
                }
              ).selectOptions;
            }
            cellProperties.readOnly =
              !tipo ||
              self.mode == "empty" ||
              self.mode == "view" ||
              factura_id;
          }
          // TIPO
          if (prop == "tipo") {
            cellProperties.renderer = function() {
              arguments[5] = (
                self.variablesMovimientos.tipos[arguments[5]] || {
                  label: ""
                }
              ).label;
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            };
            cellProperties.selectOptions = (
              self.variablesMovimientos.movimientos[movimiento] || {
                selectOptions: {}
              }
            ).selectOptions;
            cellProperties.readOnly =
              !movimiento ||
              self.mode == "empty" ||
              self.mode == "view" ||
              factura_id;
          }
          // MOVIMIENTO
          if (prop == "movimiento") {
            cellProperties.renderer = function(
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              arguments[5] = (
                self.variablesMovimientos.movimientos[value] || { label: "" }
              ).label;
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            };
            cellProperties.readOnly =
              !!movimiento ||
              self.mode == "empty" ||
              self.mode == "view" ||
              factura_id;
          }
          return cellProperties;
        },
        beforeChange: function(changes, source) {
          //console.log(changes,source);
          if (!changes) return;
          if (
            source == "loadData" ||
            source == "load" ||
            source == "modId" ||
            source == "empty"
          )
            return;
          var h = this;
          window.$.each(changes, function(index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            if (prop == "estado" && newVal == "devuelto") {
              changes.splice(index, 1);
              var importe = floatOrCero(h.getSourceData()[row]["pago_eur"]);
              if (!importe) {
                self.app.toast(
                  "Este cobro no tiene importe de pago asignado",
                  "error"
                );
                return;
              }
              /*var $dialog_impago = win.view.data("dialog_impago");
              $dialog_impago.find(".field").each(function(index, element) {
                APP.UTILS.executeFunctionByName(
                  $(this).data("widget") + ".empty",
                  APP.WIDGETS,
                  $(this)
                );
                APP.UTILS.executeFunctionByName(
                  $(this).data("widget") + ".disable",
                  APP.WIDGETS,
                  $(this)
                );
                var ro = !(typeof $(this).data("readonly") == "undefined")
                  ? String($(this).data("readonly"))
                  : "";
                if (ro != "true" && $.inArray(win.mode, ro.split(",")) == -1)
                  APP.UTILS.executeFunctionByName(
                    $(this).data("widget") + ".enable",
                    APP.WIDGETS,
                    $(this)
                  );
              });
              APP.WIDGETS.float.load(
                $dialog_impago.find('.field[data-name="row"]'),
                { row: row }
              );
              APP.WIDGETS.float.load(
                $dialog_impago.find('.field[data-name="importe"]'),
                { importe: importe }
              );
              $dialog_impago.dialog("open");*/
              self.app.openChildWindow("dialog_impago", self.win, {
                props: {
                  row,
                  initialFormData: {
                    importe,
                    fecha: new Date().yyyymmdd()
                  }
                },
                backdrop: true,
                onAccept(that) {
                  window.console.log(that);
                  var err = [];
                  var fecha = that.formData.fecha;
                  if (!fecha) err.push("Introduce la fecha del impago");
                  var numcobros = 0;
                  var importeimpago = parseFloat(that.formData.importe || 0);
                  var gastos = parseFloat(that.formData.gastos || 0);
                  let hnc = that.$refs.nuevoscobros.field.hotInstance;
                  window.$.each(hnc.getSourceData(), function(i, item) {
                    if (
                      window.$.inArray(item["tipo"], ["letra", "cheque"]) != -1
                    ) {
                      numcobros += 1;
                      if (!floatOrCero(item["compra_eur"])) {
                        err.push(
                          "Introduzca el importe para todas las líneas de nuevos cobros"
                        );
                      }
                    }
                  });
                  if (that.formData.generar_cobros && !numcobros)
                    err.push(
                      "No olvide introducir los nuevos cobros del impago o desmarque la casilla Generar cobros"
                    );
                  if (err.length) {
                    self.app.toast(err.join("\n"), "error");
                    return;
                  }
                  self.formData.movimientos[row]["estado"] = "devuelto";
                  self.formData.movimientos[row]["_op"] = "put";
                  //hm.getSourceData().pop();
                  self.formData.movimientos.push({
                    movimiento: "impago",
                    estado: "pendiente_solucionar",
                    fecha: fecha,
                    concepto: "Impago + gastos financiacion",
                    numero: "",
                    compra_eur: importeimpago + gastos,
                    pago_eur: 0
                  });
                  window.$.each(hnc.getSourceData(), function(i, item) {
                    //TODO:
                    if (!item["estado"]) return true;
                    self.formData.movimientos.push({
                      movimiento: item["movimiento"],
                      tipo: item["tipo"],
                      estado: item["estado"],
                      fecha: item["fecha"],
                      concepto: "Cobro impago",
                      numero: "",
                      compra_eur: 0,
                      pago_eur: item["compra_eur"]
                    });
                  });
                  //hm.getSourceData().push([]);
                  h.render();
                  that.$emit("close");
                  //APP.LOGIC.cliente.calc.actualizar_movtot(obj, false);
                }
              });
            }
            if (prop == "movimiento") {
              h.setDataAtRowProp(row, "compra_eur", 0, "auto");
              h.setDataAtRowProp(row, "pago_eur", 0, "auto");
              h.setDataAtRowProp(row, "compra_gr", 0, "auto");
              h.setDataAtRowProp(row, "pago_gr", 0, "auto");
            }
          });
        }
      }
    };
  },
  computed: {
    saldo_virtual() {
      var self = this;
      if (!self.formData.movimientos) return "0.00";
      var saldo_virtual = 0;
      self.formData.movimientos.forEach(function(line) {
        saldo_virtual +=
          floatOrCero(line["compra_eur"]) - floatOrCero(line["pago_eur"]);
      });
      return self.$utils.misc.formatNumber(saldo_virtual, 2);
    },
    saldo_real() {
      var self = this;
      if (!self.formData.movimientos) return "0.00";
      var saldo_real = 0;
      self.formData.movimientos.forEach(function(line) {
        if (
          !(
            line["estado"] == "pendiente_remesar" ||
            (line["tipo"] == "cheque" && line["estado"] == "pendiente_cobro") ||
            line["estado"] == "entregado_representante"
          )
        ) {
          saldo_real +=
            floatOrCero(line["compra_eur"]) - floatOrCero(line["pago_eur"]);
        }
      });
      return self.$utils.misc.formatNumber(saldo_real, 2);
    },
    metal() {
      var self = this;
      if (!self.formData.movimientos) return "0.00";
      var metal = 0;
      self.formData.movimientos.forEach(function(line) {
        metal += floatOrCero(line["compra_gr"]) - floatOrCero(line["pago_gr"]);
      });
      return self.$utils.misc.formatNumber(metal, 2);
    },
    n_activos() {
      var self = this;
      if (!self.formData.movimientos) return 0;
      var n = 0;
      self.formData.movimientos.forEach(function(line, i) {
        if (!parseInt(line["ocultar"]) && !parseInt(line["inactivo"])) n++;
      });
      return n;
    },
    n_ocultos() {
      var self = this;
      if (!self.formData.movimientos) return 0;
      var n = 0;
      self.formData.movimientos.forEach(function(line) {
        if (parseInt(line["ocultar"])) n++;
      });
      return n;
    },
    n_inactivos() {
      var self = this;
      if (!self.formData.movimientos) return 0;
      var n = 0;
      self.formData.movimientos.forEach(function(line) {
        if (parseInt(line["inactivo"])) n++;
      });
      return n;
    },
    n_total() {
      var self = this;
      if (!self.formData.movimientos) return 0;
      return self.formData.movimientos.length;
    }
  },
  methods: {
    filtrar() {
      var self = this;
      if (!self.formData.movimientos) return;
      if (!self.$refs.movimientos) return;
      let h = self.$refs.movimientos.field.hotInstance;
      let opt = self.$refs.filtro.value;
      var filasOcultas = [];
      var plugin = h.getPlugin("trimRows");
      plugin.enablePlugin();
      self.formData.movimientos.forEach(function(line, i) {
        switch (opt) {
          default:
          case "activos":
            if (parseInt(line["ocultar"]) || parseInt(line["inactivo"])) {
              filasOcultas.push(i);
              return true;
            }
            break;
          case "ocultos":
            if (!parseInt(line["ocultar"])) {
              filasOcultas.push(i);
              return true;
            }
            break;
          case "inactivos":
            if (!parseInt(line["inactivo"])) {
              filasOcultas.push(i);
              return true;
            }
            break;
          case "todos":
            break;
        }
      });
      plugin.untrimAll();
      plugin.trimRows(filasOcultas);
      h.render();
    }
  }
};
</script>